<template>
  <q-layout view="lHr LpR fff" class="astercasc-background">
    <q-img class="fixed" width="100%" height="100%" position="50% 58%" style="z-index: -1"
           fit="cover" :src="require('@/assets/img/login.jpg')"
           :placeholder-src="require('@/assets/img/login-dim.jpg')">
    </q-img>
    <div class="column fixed full-height full-width">
      <div class="col-1 column justify-center">
        <div class="row justify-start">
          <q-btn class="header-btn q-ml-lg" round href="https://doc.astercasc.com/documents"
                 flat dense icon="fa-solid fa-home" color="white"/>
        </div>
      </div>
      <div class="col-10 column justify-center">
        <div class="row justify-center">
          <div v-if="showLogin" class="animated animate__fadeInUp
          animated_duration_15  astercasc-login-card column justify-between">

            <div>
              <div class="row justify-center">
                <div class="astercasc-login-title row justify-center items-center">
                  Yuno文档系统登录
                </div>
              </div>


              <div class="row justify-center items-center">
                <q-input v-model="userAccount" class="col-9 q-ma-sm"
                         color="grey" hide-bottom-space borderless
                         placeholder="请输入文档用户账号"
                         :input-style="{fontSize: '.85rem', color:'#333', height: '2.8rem', borderRadius: '.8rem',
                         border: '2.5px solid #888', backgroundColor:'#eee',
                          margin: '0.5rem',padding: '0 1rem'} "/>

                <q-input v-model="userPasswd" class="col-9 q-ma-sm"
                         color="grey" hide-bottom-space borderless
                         placeholder="请输入文档密码" type="password"
                         :input-style="{fontSize: '.85rem', color:'#333', height: '2.8rem', borderRadius: '.8rem',
                         border: '2.5px solid #888', backgroundColor:'#eee',
                          margin: '0.5rem',padding: '0 1rem'} "/>

                <div class="col-9 q-ma-sm">
                  <q-checkbox v-model="rememberLogin" checked-icon="task_alt" color="grey-10" class="text-grey-10"
                              unchecked-icon="panorama_fish_eye" label="记住密码"/>
                </div>


              </div>
            </div>


            <div>
              <div class="row justify-center">
                <q-btn class="astercasc-login-btn q-mb-lg" label="登录" @click="docLogin"/>
              </div>
              <div class="row justify-end astercasc-login-tips q-mr-md q-mb-md">
                本系统暂不支持注册
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="col-1">
        <copyright-footer/>
      </div>
    </div>
  </q-layout>
</template>


<script setup>
import CopyrightFooter from "@/components/CopyrightFooter.vue";
import {onMounted, ref} from "vue";
import {userLogin} from "@/api/user";
import {notifyTopPositive} from "@/utils/global-notify";
import {getUserBehavior, removeUserBehavior, saveUserBehavior, saveUserTokeMsg} from "@/utils/store";

let showLogin = ref(false)
let userAccount = ref("")
let userPasswd = ref("")
let rememberLogin = ref(false)



function docLogin() {
  let currentBody = {accountMail: userAccount.value, passwd: userPasswd.value}
  //login
  userLogin(currentBody).then(data => {
        if (data && 200 === data.status) {
          notifyTopPositive("登录成功", 2000)
          saveUserTokeMsg()
          if (rememberLogin.value) {
            saveUserBehavior({
              userAccount: userAccount.value,
              userPasswd: userPasswd.value,
              rememberLogin: rememberLogin.value,
            })
          } else {
            removeUserBehavior()
          }
          window.location.href = 'https://doc.astercasc.com/documents';
        }
      }
  )
}

onMounted(() => {
  showLogin.value = true
  let savedUserBehavior = getUserBehavior()
  if (savedUserBehavior) {
    userAccount.value = savedUserBehavior.userAccount
    userPasswd.value = savedUserBehavior.userPasswd
    rememberLogin.value = savedUserBehavior.rememberLogin
  }
})


</script>


<style lang="scss">
@import "@/styles/theme-style";

.astercasc-background {
  height: 100%;
  margin: 0;
  overflow: hidden;
  overflow-anchor: none;
  touch-action: auto;
}

.animated_duration_15 {
  --animate-duration: 1.5s;
}

.astercasc-login-card {
  height: 27rem;
  width: 28rem;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 0 1px 1px rgba(254, 254, 254, 0.9), 0 20px 27px 0 rgba(0, 0, 0, 0.05);
  backdrop-filter: saturate(200%) blur(30px);
}


.astercasc-login-title {
  font-size: 1rem;
  height: 6rem;
  width: 20rem;
  padding: 1rem;
  text-align: center;
  transform: translateY(-25%);
  background-image: linear-gradient(180deg, rgba(32, 66, 70, .98), rgba(61, 90, 88, .98));
  color: $yuno_dark_text_color;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(64, 64, 64, .4);
  border-radius: 1rem;
}


.astercasc-login-btn {
  margin: 0;
  font-size: 1rem;
  text-align: center;
  color: $yuno_dark_text_color !important;
  min-height: 3rem !important;
  min-width: 10rem;
  background-image: linear-gradient(180deg, rgba(32, 66, 70, .98), rgba(61, 90, 88, .98)) !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .14), 0 7px 10px -5px rgba(64, 64, 64, .4) !important;
  border-radius: .8rem !important;
}

.astercasc-login-tips {
  font-size: .75rem;
  color: $yuno_light_text-color;
}

</style>

<style scoped lang="scss">
@import "@/styles/theme-style";


</style>