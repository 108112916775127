<template>
  <router-view/>
</template>

<script setup>

</script>

<style lang="scss">
@import "@fontsource/roboto-slab/index.css";
@import "@/styles/base-style.scss";
</style>
