<template>
  <div class="error-page-main-404">
    <span class="error-page-mask"/>
    <div class="error-page-container">
      <div class="row justify-center">
        <div class="col-lg-12 text-center">
          <h1>
            Error 404
          </h1>
          <h2>
            Erm. Page not found
          </h2>
          <p>
            页面地址错咯，返回上一页吧
          </p>
          <q-btn class="q-mt-lg error-page-btn"
                 outline push label="Go to Back" @click="toBack(thisRouter)"/>
        </div>
      </div>
    </div>
  </div>


</template>

<script setup>
import {toBack} from "@/router/index.js"
import {useRouter} from "vue-router";

const thisRouter = useRouter()
</script>

<style lang="scss" scoped>
@import "@/styles/main-style";

</style>