import extend from 'quasar/src/utils/extend/extend.js';import LocalStorage from 'quasar/src/plugins/storage/LocalStorage.js';

const YUNO_USER_DATA = "User-Data"

const YUNO_USER_TOKEN = "User-Token"

const YUNO_USER_TOKEN_MSG = "User-Token-Msg"

//============================== user behavior ==============================

export function removeUserBehavior() {
    LocalStorage.remove(YUNO_USER_DATA)
}

export function saveUserBehavior(data) {
    let oldData = getUserBehavior()
    LocalStorage.set(YUNO_USER_DATA, extend(true, oldData, data))
}

export function getUserBehavior() {
    let data = LocalStorage.getItem(YUNO_USER_DATA)
    if (!data) {
        data = {
            userAccount: "",
            userPasswd: "",
            rememberLogin: false,
        }
    }
    return data;
}


//============================== user token ==============================


export function saveUserToken(data = "") {
    localStorage.setItem(YUNO_USER_TOKEN, data)
}

export function getUserToken() {
    let data = localStorage.getItem(YUNO_USER_TOKEN)
    if (!data) {
        data = ""
    }
    return data;
}

//============================== user token msg ==============================

export function saveUserTokeMsg() {
    localStorage.setItem(YUNO_USER_TOKEN_MSG, "msg")
}


